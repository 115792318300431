/* src/components/ComingSoon.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400&display=swap');

body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.coming-soon-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(-45deg, #D39F1F, #737373, #23a6d5, #061151);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Stars Background */
.stars, .twinkling, .clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.stars {
  background: transparent url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/star.png') repeat top center;
  z-index: 1;
  opacity: 0.5;
}

/* Twinkling Stars */
.twinkling {
  background: transparent url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/star.png') repeat top center;
  z-index: 2;
  animation: twinkle 20s linear infinite;
  opacity: 0.3;
}

@keyframes twinkle {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}

/* Floating Clouds */
.clouds {
  background: transparent url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/cloud.png') repeat top center;
  z-index: 3;
  animation: moveClouds 60s linear infinite;
  opacity: 0.5;
}

@keyframes moveClouds {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}

/* Content Styling */
.content {
  position: relative;
  z-index: 4;
  text-align: center;
  padding: 20px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 4rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.subtitle {
  font-size: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}
