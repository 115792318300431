/* src/components/AddressAutocomplete.css */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  /* font-family: 'Helvetica, sans-serif'; */
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 45px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: 'Helvetica, sans-serif';
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
