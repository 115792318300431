body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-radio .form-check-input {
          border-color: #bf9731;
        }
        .custom-radio .form-check-input:checked {
          background-color: #bf9731;
          border-color: #bf9731;
        }
        .custom-radio .form-check-input:focus {
          border-color: #bf9731;
          box-shadow: 0 0 0 0.25rem rgba(211, 159, 31, 0.25);
        }


/* CalendarStyles.css */

.rbc-btn-group {
  background-color: transparent;
}

.rbc-btn-group button {
  color: #bf9731;
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  margin: 0 2px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.rbc-btn-group button:hover {
  background-color: #bf9731;
  border-color: #bf9731;
  
}

.rbc-active {
  background-color: #bf9731 !important;
  border-color: #bf9731 !important;
  color: #fff !important;
}

.rbc-toolbar-label {
  color: #061151;
  font-weight: bold;
}

.rbc-header {
  background-color: #F0F3FA;
  color: #bf9731;
}

.rbc-off-range-bg {
  background-color: #F0F3FA;
}

.rbc-today {
  background-color: #F0F3FA;
  border: none;
}

/* Ensure the toolbar has some space at the bottom */
.rbc-toolbar {
  margin-bottom: 10px;
}